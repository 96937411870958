import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Storage } from '@ionic/storage';

import { from as fromPromise, Observable } from 'rxjs';
import { FluxService } from '../../services/flux/flux.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl: string;
  private token: string = "";
  public user: any;
  private _loadData: Promise<any>;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    public flux: FluxService
  ) {
    this.apiUrl = "https://api.orientoi.fr/";
    //this.apiUrl = "https://monprofil-plateformeapi.prochorientation.fr/";

    this._loadData = this.storage.get('data').then((data) => {
      if(data != undefined && data["token"] != undefined && data["user"] != undefined){
        this.token = data.token;
        this.user = data.user;
      }
    });

  }

  public waitLoading(): Observable<any> {
    return fromPromise(this._loadData);
  };

  getToken(){
    return this.token;
  }

  setToken(e){
    this.token = "Bearer " + e;
    this.save();
  }

  setUser(e){
    this.user = e;
    this.save();
  }

  reset(){
    this.user = undefined;
    this.token = undefined;
    this.save();
  }

  save(){
    this.storage.set('data', {
      "token": this.token,
      "user": this.user
    });
  }

  get(url, auth = false){
    let header;
    if(auth){
      header = new HttpHeaders({"Authorization": this.token, "Accept": 'application/json'});
    } else {
      header = new HttpHeaders({"Accept": 'application/json'});
    }
    let httpOptions = {
      headers: header
    };

    return this.http.get(this.apiUrl + url, httpOptions);
  }

  post(url, postData, auth = false) {
    let header;
    if(auth){
      header = new HttpHeaders({'Content-Type': 'application/json', "Accept": 'application/json', "Authorization": this.token});
    } else {
      header = new HttpHeaders({'Content-Type': 'application/json', "Accept": 'application/json'});
    }
    let httpOptions = {
      headers: header
    };

    return this.http.post(this.apiUrl + url, postData, httpOptions);
  }

  put(url, putData, auth = false) {
    let header;
    if(auth){
      header = new HttpHeaders({'Content-Type': 'application/json', "Accept": 'application/json', "Authorization": this.token});
    } else {
      header = new HttpHeaders({'Content-Type': 'application/json', "Accept": 'application/json'});
    }
    let httpOptions = {
      headers: header
    };

    return this.http.put(this.apiUrl + url, putData, httpOptions);
  }
}
